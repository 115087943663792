import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

//Interfaces
import { GuardarUsuarioResponse, ListarUsuariosResponse, ObtenerUsuarioEmailResponse, ObtenerUsuarioResponse, RAWResponse } from 'interfaces/HttpResponses.interface';
import { FiltrosUsuario } from 'interfaces/Usuario.interface';

import { environment } from 'environment';

@Injectable({
  providedIn: 'root'
})
export class UsuariosService {

  constructor(private http: HttpClient) { }

    /**
   * Obtiene todos los usuarios paginados y
   * filtro nombre y area
   * @param datos
   * @returns
   */
    findAll(datos:FiltrosUsuario) {
      let params:HttpParams = new HttpParams({ fromObject: { type: 'usuarios' }});
      if(datos.nombres){
        params = params.append('nombre',datos.nombres);
      }
      if(datos.areaId){
        params = params.append('areaId',datos.areaId);
      }
      if(datos.limit){
        params = params.append('limit',datos.limit);
      }
      if(datos.offset){
        params = params.append('offset',datos.offset);
      }

      return this.http.get<ListarUsuariosResponse>(`${environment.APIS.USUARIOS}/obtenerAllUsuarios`, { params: params });
    }

    /**
   * Guardar datos usuarios
   * @param datos
   * @returns
   */
    guardar(datos: any): Observable<any> {
      const body = JSON.stringify(datos);
      return this.http.post<GuardarUsuarioResponse>(`${environment.APIS.USUARIOS}/guardarUsuario`, body, {
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
      });
    }

    obtener(id:number){
      return this.http.get<ObtenerUsuarioResponse>(`${environment.APIS.USUARIOS}/obtenerUsuario/${id}`);
    }

    async obtenerFetch(id:number){
      return fetch(`${environment.APIS.USUARIOS}/obtenerUsuario/${id}`).then(res => res.json()).then(data => data);
    }

    obtenerPorEmail(email:string){
      const body = JSON.stringify({ email });
      return this.http.post<ObtenerUsuarioEmailResponse>(`${environment.APIS.USUARIOS}/obtenerUsuario`, body);
    }

    /**
   * Elimina usuario por id
   * @param id
   * @returns
   */
    eliminar(id:number) {
      return this.http.delete<RAWResponse>(`${environment.APIS.USUARIOS}/eliminarUsuario/${id}`);
    }
}
